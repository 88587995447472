<template>
	<div class="remand">
		<div class="newplan">
			<div class="chuangjian">
				<div class="lienew">我创建的工单：</div>
				<div class="souxuan">
					<div class="souinput">
						<el-select v-model="adv_main_id" placeholder="请选择广告代理商">
							<el-option v-for="item in agentoptions" :key="item.adv_main_id" :label="item.advertiser_name" :value="item.adv_main_id" :disabled="!item.status"></el-option>
						</el-select>
					</div>
					<div class="souinput">
						<el-input v-model="adv_id" placeholder="广告主ID"></el-input>
					</div>
					<el-button type="primary" @click="onRamandList">搜索</el-button>
					<el-button type="warning" icon="el-icon-plus" @click="toTicket()">掉量超成本复审通道</el-button>
				</div>
			</div> 
		</div>
		<div class="remadnList">
			<div class="chuangjian">
				<div class="lienew">工单列表：</div>
				<el-button type="primary" icon="el-icon-plus" @click="toprogram()">创建工单</el-button>
				<el-button type="primary" icon="el-icon-plus" @click="toTiyanProgram">体验版创建工单</el-button>
				<!-- <el-button type="primary" icon="el-icon-plus" @click="toprogram()">如何查询代理商</el-button> -->
				<!-- <div class="lienew" style="margin-left:30px">提审常见问题：</div>
				<el-button type="primary" @click="toDialogPro(0)">侵权问题</el-button>
				<el-button type="primary" @click="toDialogPro(1)">夸大产品</el-button>
				<el-button type="primary" @click="toDialogPro(2)">资质问题</el-button>
				<el-button type="primary" @click="toDialogPro(3)">商标问题</el-button>
				<el-button type="primary" @click="toDialogPro(4)">侵权企业号</el-button> -->
			</div>
			<div class="tablelist">
				<el-table border :data="remandtableData" v-loading="remandLoading" style="width: 100%" empty-text="暂无工单数据，请先创建工单">
					<el-table-column prop="ticketId" label="工单ID" width="100"></el-table-column>
					<el-table-column prop="ticketStatusName" label="工单状态" width="100"></el-table-column>
					<el-table-column prop="date" label="操作" width="150">
						<template slot-scope="scope">
							<el-tag class="click" @click="onXiangxi(scope.row)" >查看处理回复</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="ticketApp" label="业务方"></el-table-column>
					<el-table-column prop="templateName" label="工单分类"></el-table-column>
					<el-table-column prop="content_params" label="广告计划/落地页ID" width="200">
						<template slot-scope="scope">
							<span>{{scope.row.content_params ? scope.row.content_params :'--'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column prop="advName" label="广告主信息" width="300">
						<template slot-scope="scope">
							<span>{{scope.row.advName}}</span><br>
							<span style="font-size:12px; color:#999">广告主账户ID:{{scope.row.advId}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="agentId" label="代理商账户ID" ></el-table-column>
					<el-table-column prop="updateTime" label="文件名" min-width="200">
						<template slot-scope="scope">
							<span>{{scope.row.file_name ? scope.row.file_name : '--'}}</span><br>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
			</div>
		</div>
		<!-- 详细信息 -->
		<el-dialog v-if="contentDialog" title="信息" :visible.sync="contentDialog" :width="content.content.length > 50 ? '700px' : content.content.length > 30 ? '400px' :''" top="50px" >
			<span slot="title" class="dialog-title">
				<span>信息</span>
				<el-tag style="margin-left:10px;cursor: pointer;" v-clipboard:copy="origin + '/workList/details?ticketId=' + templateData.ticketId + '&adv_main_id=' + templateData.adv_main_id " v-clipboard:success="onjisqCopy">复制链接</el-tag>
			</span>
			<div class="xinxiDialog" v-if="templateName == '广告计划'">广告计划id：{{content.value_id}}</div>
			<div class="xinxiDialog"><p v-html="content.content"></p></div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="contentDialog = false">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 详细信息 -->
		<el-dialog title="提审常见问题解决步骤" custom-class="ProclassDialog" :visible.sync="ProDialog" width="700px" top="50px" >
			<div class="ProDialog" v-html="problemText.text"></div>
			<div class="probutton">
				<el-button type="primary" v-if="problemText.fileUrl" @click="onFileDownload">下载模板</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data () {
		return {
			agentoptions:[],
			adv_main_id:"",//广告代理商
			adv_id:'',//广告主ID
			remandtableData:[],
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
			content:{},//工单详细信息
			templateName:'',//判断是广告计划还是千川落地页
			templateData:{},
			contentDialog:false,
			fullscreenLoading: false,
			remandLoading:false,
			origin:window.location.origin,
			ProDialog:false,
			problemList:[
				{
					text:'您好，广告中涉及使用第三方视频或图片内容，请提供相关授权或修改，谢谢。<br/>"你好，当前视频涉嫌抖音账号内容侵权，请提供抖音账号主体给广告主的视频/抖音号授权，待资质审核通过后请重新提交视频素材待审，授权所需资质如下：<br/>1）抖音账号主体授权广告主使用抖音账号投放的声明及免责声明（模板）或抖音账号主体授权广告主使用视频的授权资质，资质中明确授权使用该抖音号视频投放广告 <br/>2）已经实名认证的抖音认证截图 <br/>3）抖音账号主体身份证正反面复印件"',
					fileUrl:'https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/%E8%A7%86%E9%A2%91%E6%8E%88%E6%9D%83%E6%A8%A1%E6%9D%BF.docx',
				},{
					text:'您好，素材中不得含有夸大产品功效、虚构服务、不当承诺等涉及虚假宣传或误导用户的内容，请修改，谢谢。',
				},{
					text:'你好，当前商品无备案信息，请补充商品资质至账户后台备案（商标授权）；<br/>如有疑问，请提交【审核结果申诉/账户】类工单进行咨询，谢谢。',
				},{
					text:'您好，广告中涉及未提供权利证明的商标，请补充提供您有权使用该商标的证明，谢谢。',
				},{
					text:'"您好，当前视频涉及侵权抖音企业号，请提供抖音账号主体给广告主的视频/抖音号授权，待资质审核通过后请重新提交视频素材待审，授权所需资质如下：<br/>1）抖音账号主体授权使用该账号投放的声明及免责声明（模板）<br/>2）抖音号认证截图"',
				}
			],
			problemText:'',
		}
	},

	mounted(){
		// console.log(localStorage.getItem('TZ-USER'))

		if(localStorage.getItem('TZ-USER')){
			this.ongetAdvertiserList()
			this.ongetRepairOrderJs()
		}
	},

	methods: {
		toTicket(){
			this.$router.push('/ticketCreate')
		},
		toTiyanProgram(){
			this.$router.push('/experience/workList/createIng')
		},
		//问题弹窗
		toDialogPro(e){
			this.problemText = this.problemList[e]
			this.ProDialog = true
		},
		//问题弹窗下载模板
		onFileDownload(){
			window.open(this.problemText.fileUrl)
		},
		onjisqCopy(){
			this.$message.success("链接已复制到剪切板！")
		},
		toprogram(){
			this.$router.push('/workList/createIng')
		},
		//列表
		ongetRepairOrderJs(val){
			this.remandLoading = true
			var prame = {
				page: val,
				adv_id:this.adv_id,
				adv_main_id: this.adv_main_id,
			}
			this.$service.get(this.$api.getRepairOrderJs,prame,(res)=> {
				this.remandLoading = false
				if(res.code == 200){
					this.remandtableData = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		//获取代理商列表
		ongetAdvertiserList(){
			this.$service.get(this.$api.getAdvertiserList,{}, (res)=> {
				if(res.code == 200){
					this.agentoptions = res.data
				}
			})
		},
		//搜索
		onRamandList(){
			this.ongetRepairOrderJs(1)
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetRepairOrderJs(val)
		},
		//点击查看详细信息
		onXiangxi(row){
			// this.$router.push('/workList/details?ticketId=' + row.ticketId + '&adv_main_id=' + row.adv_main_id)
			this.templateData = row
			this.templateName = row.templateName
			this.fullscreenLoading = this.$loading({
				lock: true,
				text: '加载中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			console.log(row)
			if(row.repair_order_type != '1'){
				this.content.content = row.repair_order_desc
				this.contentDialog = true
				this.fullscreenLoading.close();
			}else{
				this.ongetTicketDetailJs(row)
			}
		},
		//获取工单详情
		ongetTicketDetailJs(row){
			var prame = {
				ticketId:row.ticketId,
				adv_main_id:row.adv_main_id,
			}
			this.$service.get(this.$api.getTicketDetailJs,prame, (res)=> {
				this.contentDialog = true
				this.fullscreenLoading.close();
				if(res.code == 200){
					this.content = res.data
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.remand{
	.newplan{
		padding-bottom: 20px;
		border-bottom: 1px solid #ededed;
	}
	.chuangjian{
		display: flex;
		align-items: center;
		.souxuan{
			display: flex;
		}
		.souinput{
			margin-right: 10px;
		}
	}
	.remadnList{
		padding-top: 20px;
		.tablelist{
			padding-top: 20px;
		}
	}
}
@media screen and (max-width: 750px) {
.remand{
	.newplan{
		padding-bottom: .25rem;
		
		.chuangjian{
			.souxuan{
				display: flex;
				width: 5.2rem;
			}
			.lienew{
				width: 1.8rem;
			}
			.souinput{
				// width: 1.9rem;
				margin-right: .1rem;
			}
			
		}
	}
	.remadnList{
		padding-top: .25rem;
		.tablelist{
			padding-top: .25rem;
		}
	}
}
}
</style>
<style lang="scss">
.remand{
	.el-dialog__body{
	}
	
}
.ProclassDialog{
	.ProDialog{
		line-height: 35px;
	}
	.probutton{
		display: flex;
		.el-button{
			margin-left: auto;
		}
	}
}

</style>
